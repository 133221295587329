import React from 'react'
// import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
// import Banner from '../components/Banner'


class PrivacyPage extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Privacy Notice | Great Harbour"
                    meta={[
                        { name: 'description', content: '' },
                    ]}
                >
                </Helmet>

                <div id="main">

                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Privacy Notice</h2>
                            </header>

                            <div className="text-normal">


                            <h4>Privacy and Cookie Notice </h4>

                            <p>This privacy notice is brought to you by Great Harbour Trading Limited (Registration number: 1990517) and applies to you if we handle information about you, including when you visit our website(s). Through this privacy notice we aim to inform you about how we collect, process and use your personal information.</p>

                            <p>For the purpose of this privacy notice the controller of personal information is Great Harbour Trading Limited ("Great Harbour ") and details of how to contact us about your personal information are set out in the Contact section at the end of this privacy notice. In this privacy notice: we/our/us means Great Harbour Trading Limited, you/your means you as a user of our services and website means <a href="https://temtum.com/">https://temtum.com/</a>, <a href="https://wallet.temtum.com/">https://wallet.temtum.com/</a> and <a href="https://greatharbour.io/">https://greatharbour.io/</a>.</p>

                            <p>If you are a visitor to <a href="https://greatharbour.io/">https://greatharbour.io/</a> the sections of this privacy notice most relevant to you are the information about when you browse our website and the cookie policy. We do not currently collect any personally identifiable information from or about users of this website and you cannot submit your information via this website. Much of this policy therefore relates to our other activities, including those in relation to the Temtum cryptocurrency, and the associated wallet services.  For more information about Temtum, visit <a href="https://temtum.com/">https://temtum.com/</a>.</p>

                            <h4>The information we collect</h4>
                            <p>We collect personal information to provide you with our services. We may be required by law or regulation to collect this information or it is necessary for specified purposes. You may also choose to provide personal information to us voluntarily.</p>
                            <p>We collect personal information in the following ways:</p>

                            <ol>
                            <li><strong>When you register for a wallet, or purchase Temtum.</strong> We collect your basic personal and contact information such as your email address, phone number, username and password. This enables us to create your Temtum wallet. Before you can purchase Temtum, you must also pass through our identity checking process (detailed below). If you purchase Temtum using a credit or debit card or BACS, we may collect your payment or bank account information.</li>
                            <li><strong>To register for publications or events.</strong> If you register to attend our events or to receive publications (such as news or updates via a newsletter) we collect your basic personal information and contact details such as your name and email address. We may use trusted third parties to hold events or bring publications to you. They will only process your personal information on our instructions for these purposes.</li>
                            <li><strong>For identity checking.</strong> Before you can purchase Temtum we are required by law and regulation for you to pass through essential checks, to verify your identity. We use a third party verification service provider to whom you provide the information required, via our Website. While Great Harbour does not retain copies of this information, it accesses information and reports made available by our service provider, to verify your identity or where it has a legitimate business need, subject to applicable law. Where we occasionally collect the information from you manually, we will retain this for as long as necessary to confirm your identity or otherwise in line with the retention criteria detailed below. Please note that Great Harbour may retain information about the outcome of the identity checking process as may be required by applicable law and regulation and in accordance with its data retention practices (as detailed below). You may be required to provide further information to us or third parties if the legal or regulatory requirements to which we are subject change or we are otherwise required to collect further information from you for compliance purposes (such as source of funds information).</li>
                            <li><strong>When you contact us.</strong> You may contact us, such as via a free form on the website. We may ask for your contact details, to respond and communicate with you.</li>
                            <li><strong>When you browse our website.</strong> We may collect statistical information about users' use of the website or their device type, for aggregated and statistical reporting. However, this is technical data from which no individual can be identified. Other non-identifying technical data may be collected through cookies, pixel tags and other similar technologies that uniquely identify your browser. For information about our use of cookies, please see our cookie policy below.</li>
                            <li><strong>When you use our wallet or mobile app.</strong> When you log in via our wallet or mobile-app we log your transactions to provide you with your transactions history.</li>
                            <li><strong>From our social media partners.</strong> We use plug-ins from social networks such as Telegram, Discord, Medium, L.I. Reddit, Twitter, Facebook and Bitcoin Talk on our website. We have links to these social platforms on the website. When you activate them (by clicking on them), the operators of the respective social networks may track that you have clicked a link from our website and may use this information. Where platforms collect information about you or from you directly, they do so as a controller of your data, independent of us. Please refer to their privacy policies for information on how they handle your personal information. Great Harbour is not responsible for data collected by these individual social media platforms.</li>
                            <li><strong>For recruitment.</strong> You may register your interest for a job opportunity via the website or contact us about, or apply for, a role. We will process the information you provide voluntarily. If you are referred to us by a third party recruitment consultant or referrer we may receive information from them (such as your details or CV). Your CV and/or the results of any ID or background checks may include sensitive personal information such as details of criminal convictions and ethnic origin. We will only process this information as necessary to comply with our recruitment and employment obligations.</li>
                            </ol>


                            <p>You may have purchased Temtum via an exchange. We do not collect your personal information if you are the buyer in such a transaction. Great Harbour is not responsible for how third party exchanges handle your personal information. Please refer to their privacy policies for more information.</p>


                            <h4>Why we collect your personal information</h4>

                            <p>The purposes for which we collect and process your personal information include:</p>

                            <ol>
                            <li><strong>To provide our services.</strong> Our primary purpose in collecting personal information is to provide you with a secure, hassle-free and personalised experience, from browsing the website, purchasing Temtum or using your Temtum wallet.</li>

                            <li><strong>To communicate about our services.</strong> We may use your email address to communicate with you about our services, including to confirm your purchase or sale of Temtum, send you administrative or account-related information, security updates or other service messages. We will only send you marketing messages by email or SMS where you have opted-in or we are otherwise permitted (as detailed below).</li>

                            <li><strong>To maintain legal and regulatory compliance.</strong> Some of our services are or may be subject to laws and regulations which require us to collect and process certain information about you, such as your identity, employment information, online identifiers and financial information.</li>

                            <li><strong>To ensure network and information security.</strong> We use security measures to protect the integrity and safety of our network and services. This may involve passing your personal information through essential security screening systems. We may be required to process your personal information to comply with applicable security laws and regulations, designed to combat security risks.</li>

                            <li><strong>Where we are permitted or with your consent, to market to you.</strong> If you have made, or taken steps to make, a purchase from us we may contact you by email or SMS with information about similar purchases or services, unless you opt-out of marketing communications when we collect your information. In other circumstances, we may send you marketing by email or SMS where you have given us your consent. This includes where you sign-up to our newsletter. An option to unsubscribe will be provided with each communication.</li>

                            <li><strong>To improve our website.</strong> Our website may use technology to record how visitors use our website, to enhance visitors' experiences of our website and services, measure usability, provide a more personalised service, meet your preferences and develop new services.</li>

                            <li><strong>To enforce our terms in our user agreement and other agreements.</strong> Given the need to maintain the integrity of our network and ensure the smooth operation of our services, we may need to process your personal information in the course of monitoring, investigating, preventing, mitigating or enforcing any potential or actual violations of the law, compliance with our terms and conditions and/or our agreements with third parties.</li>

                            <li><strong>To answer your enquiries.</strong> We may receive enquiries from you via our website, app or other channels, and we will generally need to process your personal information in order to deal with and respond to your enquiry.  We may keep a record of the enquiry and any communications between us.</li>
                            </ol>

                            <h4>The Legal Basis for Processing your Personal Information</h4>

                            <p>The main grounds that we rely upon, whether under the GDPR or otherwise, in order to process personal information are the following:</p>
                            <ol type="a">
                            <li><strong>Necessary for entering into, or performing, a contract</strong> – in order to perform obligations that we undertake in providing a service to you, or in order to take steps at your request to enter into a contract with us, it will be necessary for us to process your personal information.  Where we require information from you to enter into, or perform, a contract, the consequence of not providing some or all of that information may be that we are unable to provide the relevant service;</li>

                            <li><strong>Necessary for compliance with a legal obligation</strong> – we are subject to certain legal requirements which may require us to process your personal information.   We may also be obliged by law to disclose your personal information to a regulatory body or law enforcement agency. For example, this may be necessary to comply with  anti-money laundering or customer identification requirements;</li>

                            <li><strong>Necessary for the purposes of legitimate interests</strong> -  either we, or a third party, will need to process your personal information for the purposes of our (or a third party's) legitimate interests, provided we have established that those interests are not overridden by your rights and freedoms, including your right to have your personal information protected.  Our legitimate interests include responding to requests and enquiries from you or a third party, optimising our website and customer experience, informing you about our products and services and ensuring that our operations are conducted in an appropriate and efficient manner;</li>

                            <li><strong>Consent</strong> – in some circumstances, we may ask for your consent to process your personal information in a particular way.</li>
                            </ol>

                            <h4>How we share your Personal Information</h4>

                            <p>In certain circumstances we will share your personal information with other parties.  Details of those parties are set out below along with the reasons for sharing it.</p>

                            <ol>
                            <li><strong>Our identity check providers.</strong> We work in conjunction with a third party service provider to verify the information you provide about your identity. This is to meet our legal obligations, including to prevent fraud and comply with terrorist financing laws across various jurisdictions. We may share your personal information with other third parties as necessary in order to comply with applicable anti-money laundering, regulatory or other legal requirements. Our third party providers collect the identity verification data from you directly through an API on our website and are responsible for the secure handling of your personal information in accordance with their own privacy notice.</li>

                            <li><strong>Technology and infrastructure providers.</strong> We use third party technology providers who host our website and network on their servers, or store data on our behalf. These may be located outside of the EEA.</li>

                            <li><strong>To bring you marketing.</strong> We may use third party processors to administer and manage our marketing. They will not sell your data or use it other than for supporting our marketing operations.</li>

                            <li><strong>Payments and transactions processors.</strong> In order to implement transactions and process payments we may work with third party service providers to whom we need to disclose your personal information.</li>

                            <li><strong>IT providers.</strong> This includes analytics providers and IT consultants carrying out testing and development work.</li>

                            <li><strong>Regulatory and Law Enforcement Agencies.</strong> If we receive a request from a regulatory body or law enforcement agency, and we are permitted to do so, we may disclose certain personal information to such bodies or agencies. We may otherwise share your information with law enforcement, officials or regulators, as needed, including in the interests of the prevention or enforcement of crime, financial loss or harm to people or property or if we suspect our terms and conditions may have been violated.</li>

                            <li><strong>Tax agencies.</strong> We may disclose personal information as needed in order to fulfil our obligations to governmental tax agencies.</li>

                            <li><strong>New business owners.</strong> If we or our business merges with or is acquired by another business or company, we will share your personal information with the new owners of the business or company and their advisors.</li>
                            </ol>

                            <p>We ensure that our trusted third parties agree to comply with similar standards of privacy and confidentiality as those applied by Great Harbour. We require service providers to maintain appropriate physical, technical and administrative safeguards to protect the security and confidentiality of the personal information you entrust to us.</p>

                            <h4>International transfers</h4>

                            <p>We are established and located in the BVI. When you provide personal information to us, it may be processed within the BVI. Your personal information may also be transferred to further locations outside of the EEA as may be necessary to provide our services and fulfil our contractual obligations to you. For example, we may use service providers located outside of the EEA. Therefore, your personal information may be stored on servers, transferred, and otherwise processed outside of the EEA. To the extent that any personal information is transferred to our service providers, group companies or other third parties outside the EEA, or who will access the information from outside the EEA, we ensure that we are satisfied that your personal information is appropriately safeguarded, in accordance with our legal obligations.</p>

                            <h4>How long we will hold your information</h4>

                            <p>The appropriate retention period for us or our data processors to retain your personal information will depend upon criteria such as: the amount, nature and sensitivity of the personal information, the potential risk of harm from the unauthorised use or disclosure of the personal information, the form the personal information takes (eg, hardcopy or electronic), the purpose(s) for which we originally collected the personal information and whether we can achieve those purpose(s) through other means.</p>

                            <p>We will generally retain and use your personal information:</p>

                            <ul>
                            <li>where it is necessary to perform a contract with you or we nonetheless continue to have a legitimate interest for holding the personal information. This means that we will retain your personal information whilst you have a Temtum wallet, but also for a subsequent reasonable period for governance and legitimate operational purposes;</li>
                            <li>where necessary for crime prevention or enforcement;</li>
                            <li>in accordance with existing or future legal and regulatory requirements, including in relation to the prevention of fraud, anti-money laundering or terrorist financing; or</li>
                            <li>in order to defend claims and satisfy law enforcement obligations. This means that we will continue to retain your personal information for the period in which a claim may be brought in respect of our services.</li>
                            </ul>

                            <p>We do not currently retain a copy of the information you provide to our trusted third party to complete our ID checking process (though we may retain a copy of information or reports they make available to us).</p>

                            <h4>Your rights on Information we hold about you</h4>

                            <p>You have certain rights in relation to personal information we hold about you. Details of these rights and how to exercise them are set out below.  We will require evidence of your identity before we are able to act on your request.</p>

                            <p><strong>Right of access</strong>: you have the right at any time to ask us to inform you about our processing of your personal information, and for a copy of the personal information that we hold. Where we have good reason, and if the law permits, we can refuse your request for a copy of your personal information, or certain elements of the request. If we refuse your request or any element of it, we will provide you with our reasons for doing so.</p>

                            <p><strong>Right of correction or completion</strong>: if personal information we hold about you is not accurate or is out of date and requires amendment or correction you have a right to have the data rectified or completed. You can let us know by contacting us at privacy@greatharbour.io.</p>

                            <p><strong>Right of erasure</strong>: in certain circumstances, you have the right to request that personal information we hold about you is erased e.g. if the information is no longer necessary for the purposes for which it was collected or processed or our processing of the information is based on your consent and there are no other legal grounds on which we may process the information.</p>

                            <p><strong>Right to object to or restrict processing</strong>: in certain circumstances, you have the right to object to our processing of your personal information by contacting us at privacy@greatharbour.io.  For example, if we are processing your information on the basis of our legitimate interests and there are no compelling legitimate grounds for our processing which override your rights and interests.   You also have the right to object to use of your personal information for direct marketing purposes. You may also have the right to restrict our use of your personal information, such as in circumstances where you have challenged the accuracy of the information and during the period where we are verifying its accuracy.</p>

                            <p><strong>Right of data portability</strong>: in certain instances, you have a right to receive any personal information that we hold about you in a structured, commonly used and machine-readable format such as CSV. You can ask us to transmit that information to you or directly to a third party organisation. This right exists only in respect of personal information that:</p>

                            <ul>
                            <li>you have provided to us previously; and</li>
                            <li>is processed by us using automated means.</li>
                            </ul>

                            <p>While we are happy for such requests to be made, we are not able to guarantee technical compatibility with a third party organisation's systems.</p>

                            <p><strong>Consent</strong>: to the extent that we are processing your personal information based on your consent, you have the right to withdraw your consent at any time. You can do this by contacting us at privacy@greatharbour.io.</p>

                            <h4>Exercising your rights</h4>

                            <p>You can exercise any of the above rights by contacting us at privacy@greatharbour.io. Most of the above rights are subject to limitations and exceptions.  We will provide reasons if we are unable to comply with any request for the exercise of your rights. We may be unable to comply with requests that relate to personal information of others without their consent.</p>

                            <h4>Our Cookie Policy</h4>
                            <p><strong>What are cookies?</strong></p>

                            <p>A cookie is a text file containing a small amount of data which is downloaded to your computer or other device when you visit a website. The vast majority of websites use cookies and tracking technologies. Cookies are useful because they help us arrange the content and layout of websites and recognise those devices that have accessed our website before. They allow us to remember your preference settings and play an important role in helping us to enhance the usability and performance of our website and your experience using them.  Some cookies are also essential to enable you to move around our website and use its features.</p>

                            <p>The two types of cookies we use are known as session cookies and persistent cookies.  These may be cookies which we and/or our technology partners use.</p>
                            <ul>
                            <li>A session cookie is used each time you visit a website to give you a session ID.  This type of cookie links your actions on a website and each session cookie will only last for a browser session, at the end of which it will expire.  Each browser session will start when you open your browser window and finish when you close your browser window.  After your visit the website, the session cookies are deleted.  Each time you visit the website you will have a different session ID.</li>

                            <li>A persistent cookie allows the preferences or actions of the user across a website (or across different websites) to be remembered.  It has a longer lifetime than a session cookie and lasts for the period of time specified in the cookie (this will vary from cookie to cookie).  This type of cookie will not be deleted when you close your browser window and will be stored on your computer or other device. It will be activated every time you visit a website which created that particular persistent cookie.</li>
                            </ul>

                            <p>Within these main cookie types, there are different kinds of cookies which perform specific functions. Strictly necessary cookies are essential to enable you to move around the website and use its features. Without these cookies, services you have asked for (such as signing in to access the log-in only pages on our websites), cannot be provided. Without these cookies, our website(s) won’t work properly as they are needed for our sites to function, and can therefore not be turned off. Performance and analytics cookies collect information about how you use a website, for instance, which pages you visit most often and if you get error messages from those pages. The cookies are used to improve website usability. Functionality cookies allow websites to remember the choices users make (such as your username and password) and provide enhanced, more personal features.  These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have requested such as watching a video or commenting on a blog. Finally, advertising or tracking cookies are used to present users with adverts or other marketing more relevant to them, based on the content they visit the most on the website. For example, these cookies can show where you saw the advert; help measure the effectiveness of advertising campaigns; and limit the number of times users see an advert.</p>

                            <p><strong>Temtum website cookie list</strong></p>

                            <p>The table below sets out which cookies are currently used on our website(s), and their purpose:</p>



                            Cookie Type	Cookie Name	Purpose
                            EU Cookie Law Banner	temtum_cookie	Remembers the state of visitor acceptance to the Cookies banner.
                            Analytics and Performance	Google Analytics (utma / utmb / utmc / utmt / utmz / ga / gat / gid)	Google Analytics gathers information that helps us understand how visitors interact with the website, to create a better experience for visitors. The software provided by Google Analytics uses cookies to track visitor usage. The software will save a cookie to your computer’s hard drive in order to track and monitor your engagement and usage of the website, but will not store, save or collect personal information. You can read Google’s privacy notice here for further information: <a href="https://www.google.com/privacy.html">www.google.com/privacy.html</a>



                            <p><strong>Managing cookies on the Temtum website</strong></p>

                            <p>You can control which non-strictly necessary cookies you get and which you keep on your browser. Unless you have adjusted your web browser settings so that it will refuse our cookies, our system will issue non-strictly necessary cookies when you continue to browse our website.</p>

                            <p>Users are advised that if they wish to deny the use and saving of non-strictly necessary cookies from the website onto their computers or other device they should take necessary steps within their web browser security settings to block all cookies from our website and external serving vendors.</p>

                            <p>If you are unsure of your cookie settings, we recommend visiting the website www.aboutcookies.org, which gives you detailed step by step guidance on how to control and delete cookies depending on your browser type. You can then adjust your browser settings to your preferred level of protection.</p>

                            <h4>Complaints</h4>
                            <p>If you are unhappy about our use of your personal information, you can contact us at privacy@greatharbour.io. You are also entitled to lodge a complaint with the relevant supervisory authority in your local jurisdiction.</p>

                            <h4>Other websites</h4>
                            <p>Please note that clicking on links and banner advertisements on our website can result in your browser accessing a third party website, where data privacy practices are different to that of Great Harbour Limited.</p>

                            <p>We are not responsible for, and have no control over, information that is submitted to or collected by these third parties and you should refer to their privacy policies.</p>

                            <h4>Internet-based transfers</h4>

                            <p>Given that the internet is a global environment, using the internet to collect and process personal information necessarily involves the transmission of data on an international basis. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your data transmitted to or from our website(s) via third party networks; any transmission to us is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. That said, many of our webpages are enabled with TLS/SSL to protect data whilst in transit to our servers.</p>

                            <h4>Changes to our privacy notice</h4>

                            <p>This privacy notice can be changed by Great Harbour at any time. If we change our privacy notice in the future, we will highlight this on our website, and will advise customers of material changes or updates to our privacy notice by uploading it to our website.</p>

                            <h4>Contact</h4>

                            <p>If you have any enquires or if you would like to contact us about our processing of your personal information, including to exercise your rights as outlined above, please contact us centrally at privacy@greatharbour.io.</p>

                            <p>When you contact us, we will ask you to verify your identity.</p>





                            </div>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default PrivacyPage
